<template>
  <div class="flex-column">
    <!--banner-->
    <div class="banner-box">
      <div class="w1320 flex-column flex-center h100 banner-custom">
        <span class="banner-title animated fadeInDown">智慧社区</span>
        <span class="banner-shuoming animated fadeInDown delay-1s"
          >2020年中国软件行业优秀软件产品，政府主导型智慧社区引领者，致力于打造以党建为引领，精细化治理（网格、事件流转、综治、城管、平安）+个性化服务（政务、社工、物业、生活、养老）于一体的智慧社区平台！</span
        >
      </div>
    </div>
    <!--    应用案例-->
    <div class="yingyong-box">
      <div class="w1320 flex-column flex-center h100">
        <span class="yingyong-title">应用案例</span>
        <el-carousel
          :interval="4000"
          type="card"
          height="422px"
          :autoplay="false"
        >
          <el-carousel-item
            v-for="(item, index) in bannerList"
            :key="index"
            class="swiper-box"
          >
            <div>
              <img :src="item.src" />
              <div class="swiper-box-box flex-column flex-center">
                <span>{{ item.title }}</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!--    产品理念-->
    <div class="w1320 flex-column custom-linian">
      <span>智慧社区综合服务</span>
      <div class="flex-row flex-between custom-linian-box">
        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_chuangxin.png"
              />
            </div>
          </div>
          <span>策划服务</span>
          <span>提炼工作特色<br />一对一打造专属智慧社区品牌</span>
        </div>

        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_wending.png"
              />
            </div>
          </div>
          <span>宣传推广服务</span>
          <span>融合VR、AI等全新技术<br />打造高科技平台、展厅</span>
        </div>

        <div class="flex-column flex-align custom-item">
          <div class="waiquan flex-column flex-align flex-center">
            <div class="lansequan flex-column flex-align flex-center">
              <img
                src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/pic_anquan.png"
              />
            </div>
          </div>
          <span>信息化建设</span>
          <span>联合权威机构<br />提供版权及定制化内容服务</span>
        </div>
      </div>
    </div>

    <!--    解决方案-->
    <div class="jiejuefangan">
      <div class="w1320 h100 flex-column flex-center flex-align custom-jiejue">
        <span>智慧社区平台优势</span>
        <div class="flex-row">
          <img
            src="https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/community/pic_jiejuefangan.jpg"
          />
          <div class="shuoming flex-column flex-align flex-center">
            <span>
              一站服务百姓民生;一端支撑随时随地;一键全局决策分析;一网融合五级生态;一体覆盖全部业务;一图感知社区全貌。作为政府主导型智慧社区引领者，可以为各地政府提供从整体品牌策划、展厅建设、信息化建设、运营推广、内容服务于一体的社区综合服务。
            </span>
          </div>
        </div>
      </div>
    </div>

    <!--    业务介绍-->
    <div class="w1320 flex-column flex-align custom-yewu">
      <span>功能介绍</span>
      <div class="flex-row flex-between">
        <div
          class="flex-column flex-align custom-yewu-item"
          v-for="(item, index) in yewuList"
          :key="index"
        >
          <div class="img-box">
            <img :src="item.src" />
          </div>

          <span>{{ item.title }}</span>
          <span>{{ item.shuoming }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "communityIndex",
  data() {
    return {
      bannerList: [
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/community/lunbo4.jpg",
          title: "智慧社区项目",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/community/lunbo3.jpg",
          title: "引领智慧社区",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/community/lunbo2.jpg",
          title: "智能物联",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/community/lunbo1.jpg",
          title: "智慧社区大数据平台",
        },
      ],
      yewuList: [
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/community/gongneng01.jpg",
          title: "大数据平台",
          shuoming:
            "智慧社区，运用大数据技术，贯通市（区县）域、街道/乡镇、社区/村居、小区四级架构，各部门、层级可对实有人口、实有地理、实有法人、综治数据、社区工作、智慧党建、工作等信息进行综合分析。",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/community/gongneng02.jpg",
          title: "GIS平台",
          shuoming:
            "以三维GIS地图技术为核心，将社区的重点人员、重点事件、重点企业、社区部件、网格划分等信息在一张图上展现。如有异常事件发生，都可以通过GIS地图及时展现，并可以在地图上便捷操作处理。",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/community/gongneng03.jpg",
          title: "党建平台",
          shuoming:
            "森普作为政府主导型智慧社区引领者，森普智慧社区搭建的党建平台能够实现党建工作的细化,助力社区、社区工作的优良化,用行之有效的方式实现智慧社区和智慧社区的建设。",
        },
        {
          src: "https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/community/gongneng04.jpg",
          title: "网格平台",
          shuoming:
            "网格化管理主要是依托于统一的管理以及数字化平台，将社区按照一定的标准划分成统一的单元网格，通过加强对单元网格的部件和事件巡查，建立一种监督和处置互相分离的形式。 通过一定标准将社区划分为多个网格，在线上对网格内的“人、地、物、情、事、组织”信息统一管理。",
        },
      ],
    };
  },
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
  },
  methods: {
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.banner-box {
  height: 580px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/community/banner_pro_community_index_2.webp")
    no-repeat center center;
  background-size: auto 100%;
}

.banner-title {
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 56px;
}

.banner-shuoming {
  font-size: 24px;
  line-height: 48px;
  font-weight: 400;
  color: #ffffff;
}

.banner-custom span {
  text-align: left;
}

.yingyong-box {
  height: 810px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/bg_yyal.png")
    no-repeat center center;
  background-size: auto 100%;
}

.yingyong-title {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 94px;
}

/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  margin-top: 26px;
  background-color: #d2d2d2;
  width: 86px;
  height: 5px;
  opacity: 1;
}

/deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 86px;
  height: 5px;
  background-color: #ffc244;
  opacity: 1;
}

.custom-linian {
  padding: 108px 0 131px 0;
}

.custom-linian > span {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 50px;
}

.waiquan {
  width: 188px;
  height: 188px;
  border-radius: 50%;
  border: 1px dashed #3485ee;
  transition: all 0.3s ease-out;
}

.lansequan {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #3485ee;
}

.custom-item > span:nth-of-type(1) {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 22px;
  margin-top: 50px;
}

.custom-item > span:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.custom-item:nth-of-type(1) {
  /*max-width: 240px;*/
  max-width: 320px;
}

.custom-item:nth-of-type(2) {
  max-width: 320px;
  /*margin-left: 100px;*/
}

.custom-item:nth-of-type(3) {
  /*max-width: 440px;*/
  max-width: 320px;
  /*margin-right: -90px;*/
}

.custom-linian-box {
  padding: 0 54px;
}

.jiejuefangan {
  height: 884px;
  background: url("https://sbyd-website.oss-cn-hangzhou.aliyuncs.com/assets/heqi/bg_jiejuefangan.png")
    no-repeat center center;
  background-size: auto 100%;
}

.custom-jiejue > span {
  font-size: 44px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 85px;
}

.shuoming {
  width: 342px;
  height: 533px;
  background: rgba(9, 22, 40, 0.27);
  margin-left: 23px;
}

.shuoming > span {
  display: block;
  width: 286px;
  height: 389px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  text-align: justify;
}

.custom-yewu {
  padding: 134px 0 79px 0;
}

.custom-yewu > span {
  font-size: 44px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 80px;
}

.custom-yewu > div {
  width: 100%;
}

.custom-yewu-item {
  width: 317px;
}

.custom-yewu-item > span:nth-of-type(1) {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin: 21px 0 22px 0;
}

.custom-yewu-item > span:nth-of-type(2) {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  text-align: justify;
}

.waiquan:hover {
  animation: fanzhuan 0.4s forwards;
}

@keyframes fanzhuan {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0);
  }
}

.img-box {
  width: 317px;
  height: 202px;
  overflow: hidden;
}

.img-box > img {
  transition: all 0.3s ease-out;
}

.img-box > img:hover {
  transform: scale(1.2);
}

.swiper-box {
  /*width: 600px;*/
  height: 438px;
  overflow: hidden;
}

.swiper-box > div {
  /*width: 600px;*/
  height: 400px;
  position: relative;
  overflow: hidden;
}

.swiper-box > div > img {
  display: block;
  width: 100%;
  height: auto;
}

.swiper-box-box {
  height: 90px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.swiper-box-box > span {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

@media screen and (max-width: 1300px) {
  .shuoming {
    /*display: block;*/
    width: 300px;
    /*height: 533px;*/
  }

  .custom-jiejue > div {
    transform: scale(0.9);
    /*width: 600px;
    display: block;
    height: auto;*/
  }

  .custom-yewu > div > div {
    transform: scale(0.9);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .shuoming {
    /*display: block;*/
    width: 300px;
    /*height: 533px;*/
  }

  .custom-jiejue > div {
    transform: scale(0.9);
    /*width: 600px;
    display: block;
    height: auto;*/
  }

  .custom-yewu > div > div {
    transform: scale(0.9);
  }
}
</style>
